/* eslint-disable max-len */
import React from 'react'

import Layout from '../../Layout'
import SEO from '../../SEO'

import {
  Container, Title, P, Section,
} from '../../components/Legals'

const Legal = () => (
  <Layout withPadding={false} restricted={false}>
    <SEO title="Politique d'expédition" />
    <Container>
      <P><Title>EXPÉDITION</Title></P>
      <P>----</P>
      <Section>
        <P>DÉLAIS D&apos;EXPÉDITION</P>
        <P>Les articles commandés sur notre site et qui ne seront pas récupérés sur place seront expédiés en moyenne sous 3 jours ouvrés (du lundi au vendredi, hors jours fériés).</P>
      </Section>
      <Section>
        <P>TARIFS D&apos;EXPÉDITION</P>
        <P>Tous nos articles sont expédiés via des transporteurs et leurs frais de port sont calculés en fonction de leur poids et/ou taille.</P>
        <P>Nous ne touchons pas de commission sur les frais de transport et nous les facturons au tarif en vigueur chez nos transporteurs.</P>
      </Section>
      <Section>
        <P>RETRAIT SUR PLACE</P>
        <P>Certains produits peuvent être retirés dans nos locaux, cette option est précisée avant le paiement, lors du choix du mode d&apos;envoi. Seuls ces articles peuvent être retirés sur place.</P>
        <P>Choisir le retrait sur place implique d&apos;être contacté.e par e-mail afin de convenir d&apos;un rendez-vous pour retirer ses articles. Nos locaux ne sont pas ouverts au public et nous vous demanderons de n&apos;y venir en aucun cas sans y avoir été invité.e au préalable. L&apos;adresse de retrait ne correspond pas forcément à celle que vous trouverez sur notre site, elle vous sera communiquée par e-mail le cas échéant.</P>
      </Section>
    </Container>
  </Layout>
)

export default Legal
